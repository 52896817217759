import * as React from "react"
import { FormLogin } from "../components/index/FormLogin"
import Layout from '../components/layout'


// markup
const Login = () => {
  return (
    <Layout>
      <div className="container-xl">
        <FormLogin />
      </div>
    </Layout>
  )
}

export default Login
