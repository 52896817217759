import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { startLogin, startChecking } from '../../action/auth';
import { useForm } from '../../hooks/useForm';
import { Loading } from '../Loading';
import { navigate } from "gatsby"

export const FormLogin = () => {
    const dispatch = useDispatch()
    const { checking, uid } = useSelector(state => state.auth);

    const [ login, handleInputChange ] = useForm({
        rut: '',
        password: ''
    })
    const { rut, password } = login;
    
    const handleLogin = (e) => {
        e.preventDefault();
        dispatch(startLogin(rut, password));
    };

    useEffect(() => {
        dispatch(startChecking());
    }, [dispatch]);

    useEffect(() => {
        if(uid){
            setTimeout(() => {
                navigate("/administradorLibroDigital/")
            }, 1500);
        }
    }, [uid]);
    
    if ( checking ) {
        return (
            <Loading />
        )
    };

    return (
        <form className="card">
            <div className="card-body">
                <h5>Formulario de ingreso</h5>
                <div className="row mb-3 mt-3">
                    <label htmlFor="rut" className="col-3 col-form-label">Rut</label>
                    <div className="col-9">
                        <input 
                            type="text" 
                            className="form-control" 
                            id="rut"
                            name="rut"
                            value={ rut }
                            onChange={ handleInputChange }
                        />
                    </div>
                </div>
                <div className="row mb-3">
                    <label htmlFor="password" className="col-3 col-form-label">Password</label>
                    <div className="col-9">
                        <input 
                            type="password" 
                            className="form-control" 
                            id="password"
                            name="password"
                            value={ password }
                            onChange={ handleInputChange }
                        />
                    </div>
                </div>
                <button 
                    type="submit" 
                    className="btn btn-primary"
                    onClick={ handleLogin }
                >Ingresar</button>
            </div>
        </form>
    )
}